
	import http from "../../../../common/services/http";
	import { defineComponent, computed, ref, reactive, onMounted } from "vue";
	import moment from "moment";
	import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const headers = {
				name: "Name",
				code: "Code",
			};
			const form = ref({});

			const farms = ref([]);
			const farm = ref("");

			const products = ref({
				data: [],
			});

			const product = reactive({
				name: "",
				code: "",
			});

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);
			const day = ref(0);
			const date = ref(new Date());

			const today = computed(() => new Date());
			const week = ref({
				start: new Date(),
				end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
			});
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function createForm(a: any) {
				a.map((b: any) => {
					Object.defineProperty(form.value, b.id, {
						value: {
							projected: 0,
							amend: 0,
							actual: 0,
							packout: 0,
						},
						writable: true,
						enumerable: true,
						configurable: true,
					});
				});
			}

			function fetchProducts() {
				processing.value = true;
				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;

					createForm(res.data);
				});
			}

			function createProduct() {
				http.post("/api/products", product)
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchProducts();
					});
			}

			function updateProduct(product: any) {
				product._method = 'PUT'
				http.post(`/api/products/${product.id}`, product)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchProducts();
					});
			}

			function deleteProduct(id: any) {
				http.post(`/api/products/${id}`, { _method: "DELETE" }).then((res) => {
					fetchProducts();
				});
			}

			function setDay(i = 0, d: any) {
				day.value = i;
				date.value = d;
			}

			function getDates(start: any, stop: any) {
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			function formatDate(d: any, f = "PPPP") {
				return format(d, f);
			}

			onMounted(() => {
				//fetchProducts();
			});

			return {
				headers,
				processing,
				products,
				createProduct,
				created,
				product,
				updateProduct,
				updated,
				deleteProduct,
				formatDate,
				today,
				week,
				masks,
				getDates,
				setDay,
				day,
				date,
				form,
				farm,
			};
		},
	});
