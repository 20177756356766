<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-sm d-none">
			<div class="ng-block-between">
				<div class="ng-block-head-content">
					<h3 class="ng-block-title page-title">Sales Overview</h3>
					<div class="ng-block-des d-print-none text-soft">
						<p>
							Week of
							<b>{{ formatDate(week.start, "E, do MMM") }}</b> -
							<b>{{ formatDate(week.end, "E, do MMM") }}</b>
						</p>
					</div>
				</div>

				<div class="ng-block-head-content">
					<div class="toggle-wrap ng-block-tools-toggle">
						<a
							href="#"
							class="btn btn-icon btn-trigger toggle-expand mr-n1"
							data-target="pageMenu"
							><em class="icon ni ni-more-v"></em
						></a>
						<div
							class="toggle-expand-content"
							data-content="pageMenu"
						>
							<ul class="ng-block-tools g-3">
								<li>
									<div class="drodown">
										<a
											href="#selectWeek"
											data-toggle="modal"
											class="btn btn-white btn-dim btn-outline-primary"
										>
											<em
												class="d-none d-sm-inline icon ni ni-calender-date"
											></em>
											<span>
												<span
													class="d-none d-md-inline"
												>
													From
												</span>
												{{
													formatDate(
														week.start,
														"E, do MMM"
													)
												}}
											</span>
											<em
												class="dd-indc icon ni ni-chevron-right"
											></em>
										</a>

										<div
											class="modal fade"
											tabindex="-1"
											id="selectWeek"
										>
											<div
												class="modal-dialog"
												role="document"
											>
												<div
													class="modal-content justify-content-center"
												>
													<button
														id="hideNewForm"
														class="close"
														data-dismiss="modal"
														aria-label="Close"
													>
														<em
															class="icon ni ni-cross text-white"
														></em>
													</button>

													<div
														class="modal-header bg-primary"
													>
														<h5
															class="modal-title text-white"
														>
															Select Date Range
														</h5>
													</div>

													<div
														class="modal-body row justify-content-center"
													>
														<div
															class="col-md-7 justify-content-center text-center mb-1"
														>
															<v-date-picker
																value="range"
																v-model="week"
																is-range
															/>
														</div>
														<div
															class="col-md-5 text-center"
														>
															<h5>Starting on</h5>
															<p>
																{{
																	formatDate(
																		week.start
																	)
																}}
															</p>

															<h5>Ending on</h5>
															<p>
																{{
																	formatDate(
																		week.end
																	)
																}}
															</p>

															<button
																class="btn btn-outline-primary float-right"
																data-dismiss="modal"
															>
																<span>
																	Use Selected
																	Dates
																</span>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li class="ng-block-tools-opt">
									<a href="#" class="btn btn-primary"
										><em class="icon ni ni-reports"></em
										><span>Reports</span></a
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="ng-block d-none">
			<div class="row g-gs">
				<div class="col-xxl-6">
					<div class="row g-gs">
						<div class="col-lg-6 col-xxl-12">
							<div class="card card-bordered">
								<div class="card-inner">
									<div
										class="card-title-group align-start mb-2"
									>
										<div class="card-title">
											<h6 class="title">Sales Revenue</h6>
											<p>
												In last 30 days revenue from
												subscription.
											</p>
										</div>
										<div class="card-tools">
											<em
												class="card-hint icon ni ni-help-fill"
												data-toggle="tooltip"
												data-placement="left"
												title=""
												data-original-title="Revenue from subscription"
											></em>
										</div>
									</div>
									<div
										class="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap"
									>
										<div
											class="ng-sale-data-group flex-md-nowrap g-4"
										>
											<div class="ng-sale-data">
												<span class="amount"
													>14,299.59
													<span
														class="change down text-danger"
														><em
															class="icon ni ni-arrow-long-down"
														></em
														>16.93%</span
													></span
												><span class="sub-title"
													>This Month</span
												>
											</div>
											<div class="ng-sale-data">
												<span class="amount"
													>7,299.59
													<span
														class="change up text-success"
														><em
															class="icon ni ni-arrow-long-up"
														></em
														>4.26%</span
													></span
												><span class="sub-title"
													>This Week</span
												>
											</div>
										</div>
										<div class="ng-sales-ck sales-revenue">
											<div class="chartjs-size-monitor">
												<div
													class="chartjs-size-monitor-expand"
												>
													<div class=""></div>
												</div>
												<div
													class="chartjs-size-monitor-shrink"
												>
													<div class=""></div>
												</div>
											</div>
											<canvas
												class="sales-bar-chart chartjs-render-monitor"
												id="salesRevenue"
												style="display: block; width: 164px; height: 64px;"
												width="164"
												height="64"
											></canvas>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6 col-xxl-12">
							<div class="row g-gs">
								<div class="col-sm-6 col-lg-12 col-xxl-6">
									<div class="card card-bordered">
										<div class="card-inner">
											<div
												class="card-title-group align-start mb-2"
											>
												<div class="card-title">
													<h6 class="title">
														Active Subscriptions
													</h6>
												</div>
												<div class="card-tools">
													<em
														class="card-hint icon ni ni-help-fill"
														data-toggle="tooltip"
														data-placement="left"
														title=""
														data-original-title="Total active subscription"
													></em>
												</div>
											</div>
											<div
												class="align-end flex-sm-wrap g-4 flex-md-nowrap"
											>
												<div class="ng-sale-data">
													<span class="amount"
														>9.69K</span
													><span class="sub-title"
														><span
															class="change down text-danger"
															><em
																class="icon ni ni-arrow-long-down"
															></em
															>1.93%</span
														>since last month</span
													>
												</div>
												<div class="ng-sales-ck">
													<div
														class="chartjs-size-monitor"
													>
														<div
															class="chartjs-size-monitor-expand"
														>
															<div class=""></div>
														</div>
														<div
															class="chartjs-size-monitor-shrink"
														>
															<div class=""></div>
														</div>
													</div>
													<canvas
														class="sales-bar-chart chartjs-render-monitor"
														id="activeSubscription"
														width="67"
														height="56"
														style="display: block; width: 67px; height: 56px;"
													></canvas>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-6 col-lg-12 col-xxl-6">
									<div class="card card-bordered">
										<div class="card-inner">
											<div
												class="card-title-group align-start mb-2"
											>
												<div class="card-title">
													<h6 class="title">
														Avg Subscriptions
													</h6>
												</div>
												<div class="card-tools">
													<em
														class="card-hint icon ni ni-help-fill"
														data-toggle="tooltip"
														data-placement="left"
														title=""
														data-original-title="Daily Avg. subscription"
													></em>
												</div>
											</div>
											<div
												class="align-end flex-sm-wrap g-4 flex-md-nowrap"
											>
												<div class="ng-sale-data">
													<span class="amount"
														>346.2</span
													><span class="sub-title"
														><span
															class="change up text-success"
															><em
																class="icon ni ni-arrow-long-up"
															></em
															>2.45%</span
														>since last week</span
													>
												</div>
												<div class="ng-sales-ck">
													<div
														class="chartjs-size-monitor"
													>
														<div
															class="chartjs-size-monitor-expand"
														>
															<div class=""></div>
														</div>
														<div
															class="chartjs-size-monitor-shrink"
														>
															<div class=""></div>
														</div>
													</div>
													<canvas
														class="sales-bar-chart chartjs-render-monitor"
														id="totalSubscription"
														width="74"
														height="56"
														style="display: block; width: 74px; height: 56px;"
													></canvas>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-xxl-6">
					<div class="card card-bordered h-100">
						<div class="card-inner">
							<div class="card-title-group align-start gx-3 mb-3">
								<div class="card-title">
									<h6 class="title">Sales Overview</h6>
									<p>
										In 30 days sales of product
										subscription.
										<a href="#">See Details</a>
									</p>
								</div>
								<div class="card-tools">
									<div class="dropdown">
										<a
											href="#"
											class="btn btn-primary btn-dim d-none d-sm-inline-flex"
											data-toggle="dropdown"
											><em
												class="icon ni ni-download-cloud"
											></em
											><span
												><span
													class="d-none d-md-inline"
													>Download</span
												>
												Report</span
											></a
										><a
											href="#"
											class="btn btn-icon btn-primary btn-dim d-sm-none"
											data-toggle="dropdown"
											><em
												class="icon ni ni-download-cloud"
											></em
										></a>
										<div
											class="dropdown-menu dropdown-menu-right"
										>
											<ul class="link-list-opt no-bdr">
												<li>
													<a href="#"
														><span
															>Download Mini
															Version</span
														></a
													>
												</li>
												<li>
													<a href="#"
														><span
															>Download Full
															Version</span
														></a
													>
												</li>
												<li class="divider"></li>
												<li>
													<a href="#"
														><em
															class="icon ni ni-opt-alt"
														></em
														><span
															>More Options</span
														></a
													>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div
								class="ng-sale-data-group align-center justify-between gy-3 gx-5"
							>
								<div class="ng-sale-data">
									<span class="amount">$82,944.60</span>
								</div>
								<div class="ng-sale-data">
									<span class="amount sm"
										>1,937 <small>Subscribers</small></span
									>
								</div>
							</div>
							<div class="ng-sales-ck large pt-4">
								<div class="chartjs-size-monitor">
									<div class="chartjs-size-monitor-expand">
										<div class=""></div>
									</div>
									<div class="chartjs-size-monitor-shrink">
										<div class=""></div>
									</div>
								</div>
								<canvas
									class="sales-overview-chart chartjs-render-monitor"
									id="salesOverview"
									width="542"
									height="176"
									style="display: block; width: 542px; height: 176px;"
								></canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="ng-block">
			<div class="row g-gs">
				<div class="col-md-6">
					<div class="card card-bordered card-full">
						<div class="ng-wg1">
							<div class="ng-wg1-block">
								<div class="ng-wg1-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 90 90"
									>
										<rect
											x="5"
											y="7"
											width="60"
											height="56"
											rx="7"
											ry="7"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<rect
											x="25"
											y="27"
											width="60"
											height="56"
											rx="7"
											ry="7"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<rect
											x="15"
											y="17"
											width="60"
											height="56"
											rx="7"
											ry="7"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="15"
											y1="29"
											x2="75"
											y2="29"
											fill="none"
											stroke="#00964c"
											stroke-miterlimit="10"
											stroke-width="2"
										/>
										<circle
											cx="53"
											cy="23"
											r="2"
											fill="#c4cefe"
										/>
										<circle
											cx="60"
											cy="23"
											r="2"
											fill="#c4cefe"
										/>
										<circle
											cx="67"
											cy="23"
											r="2"
											fill="#c4cefe"
										/>
										<rect
											x="22"
											y="39"
											width="20"
											height="20"
											rx="2"
											ry="2"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<circle
											cx="32"
											cy="45.81"
											r="2"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M29,54.31a3,3,0,0,1,6,0"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="49"
											y1="40"
											x2="69"
											y2="40"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="49"
											y1="51"
											x2="69"
											y2="51"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="49"
											y1="57"
											x2="59"
											y2="57"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="64"
											y1="57"
											x2="66"
											y2="57"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="49"
											y1="46"
											x2="59"
											y2="46"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="64"
											y1="46"
											x2="66"
											y2="46"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</svg>
								</div>
								<div class="ng-wg1-text">
									<h5 class="title">Weekly Forecast</h5>
									<p>
										Set it now
									</p>
								</div>
							</div>
							<div class="ng-wg1-action">
								<router-link to="/picks/weekly" class="link">
									<span>Set Projections</span>
									<em class="icon ni ni-chevron-right"></em>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card card-bordered card-full">
						<div class="ng-wg1">
							<div class="ng-wg1-block">
								<div class="ng-wg1-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 90 90"
									>
										<path
											d="M26,70.78V24.5a7,7,0,0,1,7-7H69a9,9,0,0,1,9,9v49a7,7,0,0,1-7,7H16.55S25.72,78.89,26,70.78Z"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M7,30.5H26a0,0,0,0,1,0,0V73.9a8.6,8.6,0,0,1-8.6,8.6H13.6A8.6,8.6,0,0,1,5,73.9V32.5a2,2,0,0,1,2-2Z"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<circle
											cx="71.5"
											cy="21"
											r="13.5"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<rect
											x="34"
											y="33.5"
											width="16"
											height="8"
											rx="1"
											ry="1"
											fill="#c4cefe"
										/>
										<line
											x1="35"
											y1="46.5"
											x2="67"
											y2="46.5"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="53.5"
											x2="67"
											y2="53.5"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="59.5"
											x2="67"
											y2="59.5"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="64.5"
											x2="67"
											y2="64.5"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="71.5"
											x2="51"
											y2="71.5"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M75.24,23.79a5.2,5.2,0,0,1-6.42,2.57,5.78,5.78,0,0,1-3.26-7.25,5.25,5.25,0,0,1,6.8-3.47,5.35,5.35,0,0,1,2,1.34l2.75,2.75"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="77.75 16.61 77.75 20.61 73.75 20.61"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</svg>
								</div>
								<div class="ng-wg1-text">
									<h5 class="title">Daily Picks</h5>
									<p>
										Farm Produce
									</p>
								</div>
							</div>
							<div class="ng-wg1-action">
								<router-link to="/picks" class="link">
									<span>Set Today's Picks</span>
									<em class="icon ni ni-chevron-right"></em>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card card-bordered card-full">
						<div class="ng-wg1">
							<div class="ng-wg1-block">
								<div class="ng-wg1-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 90 90"
									>
										<path
											d="M68.14,80.86,30.21,72.69a5.93,5.93,0,0,1-4.57-7l12.26-56A6,6,0,0,1,45,5.14l28.18,6.07L85.5,29.51,75.24,76.33A6,6,0,0,1,68.14,80.86Z"
											fill="#eff1ff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="73 12.18 69.83 26.66 85.37 30.08"
											fill="#eff1ff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M66.26,71.15,29.05,82.46a6,6,0,0,1-7.46-4L4.76,23.15a6,6,0,0,1,4-7.47l27.64-8.4L56.16,17.39,70.24,63.68A6,6,0,0,1,66.26,71.15Z"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="36.7 8.22 41.05 22.53 56.33 17.96"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M68,85H29a6,6,0,0,1-6-6V21a6,6,0,0,1,6-6H58L74,30.47V79A6,6,0,0,1,68,85Z"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="58 16 58 31 74 31.07"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="45"
											y1="41"
											x2="61"
											y2="41"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="48"
											x2="61"
											y2="48"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="55"
											x2="61"
											y2="55"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="63"
											x2="61"
											y2="63"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="35"
											y1="69"
											x2="51"
											y2="69"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<text
											transform="translate(34.54 43.18) scale(0.99 1)"
											font-size="9.31"
											fill="#00964c"
											font-family="Nunito-Black, Nunito Black"
										>
											$
										</text>
									</svg>
								</div>
								<div class="ng-wg1-text">
									<h5 class="title">
										Raw Material Availabilty
									</h5>
									<p>
										View available produce
									</p>
								</div>
							</div>
							<div class="ng-wg1-action">
								<router-link to="/availability" class="link">
									<span>Availability</span>
									<em class="icon ni ni-chevron-right"></em>
								</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card card-bordered card-full">
						<div class="ng-wg1">
							<div class="ng-wg1-block">
								<div class="ng-wg1-img">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 90 90"
									>
										<path
											d="M40.74,5.16l38.67,9.23a6,6,0,0,1,4.43,7.22L70.08,80a6,6,0,0,1-7.17,4.46L24.23,75.22A6,6,0,0,1,19.81,68L33.56,9.62A6,6,0,0,1,40.74,5.16Z"
											fill="#eff1ff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M50.59,6.5,11.18,11.73a6,6,0,0,0-5.13,6.73L13.85,78a6,6,0,0,0,6.69,5.16l39.4-5.23a6,6,0,0,0,5.14-6.73l-7.8-59.49A6,6,0,0,0,50.59,6.5Z"
											fill="#eff1ff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<rect
											x="15"
											y="15"
											width="54"
											height="70"
											rx="6"
											ry="6"
											fill="#fff"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<line
											x1="42"
											y1="77"
											x2="58"
											y2="77"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<circle
											cx="38"
											cy="77"
											r="0.5"
											fill="#c4cefe"
											stroke="#c4cefe"
											stroke-miterlimit="10"
										/>
										<line
											x1="42"
											y1="72"
											x2="58"
											y2="72"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<circle
											cx="38"
											cy="72"
											r="0.5"
											fill="#c4cefe"
											stroke="#c4cefe"
											stroke-miterlimit="10"
										/>
										<line
											x1="42"
											y1="66"
											x2="58"
											y2="66"
											fill="none"
											stroke="#c4cefe"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<circle
											cx="38"
											cy="66"
											r="0.5"
											fill="#c4cefe"
											stroke="#c4cefe"
											stroke-miterlimit="10"
										/>
										<path
											d="M46,40l-15-.3V40A15,15,0,1,0,46,25h-.36Z"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M42,22A14,14,0,0,0,28,36H42V22"
											fill="#e3e7fe"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="33.47 30.07 28.87 23 23 23"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<polyline
											points="25 56 35 56 40.14 49"
											fill="none"
											stroke="#00964c"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</svg>
								</div>
								<div class="ng-wg1-text">
									<h5 class="title">Orders</h5>
									<p>
										Set and view orders
									</p>
								</div>
							</div>
							<div class="ng-wg1-action">
								<router-link to="/orders" class="link">
									<span>View Orders</span>
									<em class="icon ni ni-chevron-right"></em>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="ng-block">
			<div class="card card-bordered">
				<div class="card-inner">
					<div class="ng-help">
						<div class="ng-help-img">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 120 118"
							>
								<path
									d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
									transform="translate(0 -1)"
									fill="#f6faff"
								/>
								<rect
									x="18"
									y="32"
									width="84"
									height="50"
									rx="4"
									ry="4"
									fill="#fff"
								/>
								<rect
									x="26"
									y="44"
									width="20"
									height="12"
									rx="1"
									ry="1"
									fill="#e5effe"
								/>
								<rect
									x="50"
									y="44"
									width="20"
									height="12"
									rx="1"
									ry="1"
									fill="#e5effe"
								/>
								<rect
									x="74"
									y="44"
									width="20"
									height="12"
									rx="1"
									ry="1"
									fill="#e5effe"
								/>
								<rect
									x="38"
									y="60"
									width="20"
									height="12"
									rx="1"
									ry="1"
									fill="#e5effe"
								/>
								<rect
									x="62"
									y="60"
									width="20"
									height="12"
									rx="1"
									ry="1"
									fill="#e5effe"
								/>
								<path
									d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
									transform="translate(0 -1)"
									fill="#00964c"
								/>
								<path
									d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
									transform="translate(0 -1)"
									fill="#00964c"
								/>
								<path
									d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
									transform="translate(0 -1)"
									fill="none"
									stroke="#00964c"
									stroke-miterlimit="10"
									stroke-width="2"
								/>
								<line
									x1="40"
									y1="22"
									x2="57"
									y2="22"
									fill="none"
									stroke="#fffffe"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
								<line
									x1="40"
									y1="27"
									x2="57"
									y2="27"
									fill="none"
									stroke="#fffffe"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
								<line
									x1="40"
									y1="32"
									x2="50"
									y2="32"
									fill="none"
									stroke="#fffffe"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
								<line
									x1="30.5"
									y1="87.5"
									x2="30.5"
									y2="91.5"
									fill="none"
									stroke="#9cabff"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<line
									x1="28.5"
									y1="89.5"
									x2="32.5"
									y2="89.5"
									fill="none"
									stroke="#9cabff"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<line
									x1="79.5"
									y1="22.5"
									x2="79.5"
									y2="26.5"
									fill="none"
									stroke="#9cabff"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<line
									x1="77.5"
									y1="24.5"
									x2="81.5"
									y2="24.5"
									fill="none"
									stroke="#9cabff"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<circle
									cx="90.5"
									cy="97.5"
									r="3"
									fill="none"
									stroke="#9cabff"
									stroke-miterlimit="10"
								/>
								<circle
									cx="24"
									cy="23"
									r="2.5"
									fill="none"
									stroke="#9cabff"
									stroke-miterlimit="10"
								/>
							</svg>
						</div>
						<div class="ng-help-text">
							<h5>Weekly Summary</h5>
							<p class="text-soft">
								View Weekly orders summary by customer
							</p>
						</div>
						<div class="ng-help-action">
							<router-link
								to="/orders/week"
								class="btn btn-lg btn-outline-primary"
							>
								View Summary
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import { defineComponent, computed, ref, reactive, onMounted } from "vue";
	import moment from "moment";
	import { format } from "date-fns";

	export default defineComponent({
		setup(props) {
			const headers = {
				name: "Name",
				code: "Code",
			};
			const form = ref({});

			const farms = ref([]);
			const farm = ref("");

			const products = ref({
				data: [],
			});

			const product = reactive({
				name: "",
				code: "",
			});

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);
			const day = ref(0);
			const date = ref(new Date());

			const today = computed(() => new Date());
			const week = ref({
				start: new Date(),
				end: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
			});
			const masks = ref({
				input: "YYYY-MM-DD",
			});

			function createForm(a: any) {
				a.map((b: any) => {
					Object.defineProperty(form.value, b.id, {
						value: {
							projected: 0,
							amend: 0,
							actual: 0,
							packout: 0,
						},
						writable: true,
						enumerable: true,
						configurable: true,
					});
				});
			}

			function fetchProducts() {
				processing.value = true;
				http.get(`/api/products?fetch=all`).then((res) => {
					products.value = res.data;
					processing.value = false;

					createForm(res.data);
				});
			}

			function createProduct() {
				http.post("/api/products", product)
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchProducts();
					});
			}

			function updateProduct(product: any) {
				product._method = 'PUT'
				http.post(`/api/products/${product.id}`, product)
					.then((res) => {
						if (res.data.success) {
							updated.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideEditForm")?.click();
						fetchProducts();
					});
			}

			function deleteProduct(id: any) {
				http.post(`/api/products/${id}`, { _method: "DELETE" }).then((res) => {
					fetchProducts();
				});
			}

			function setDay(i = 0, d: any) {
				day.value = i;
				date.value = d;
			}

			function getDates(start: any, stop: any) {
				const dates = [];
				let currentDate = moment(start);
				const stopDate = moment(stop);
				while (currentDate <= stopDate) {
					dates.push(moment(currentDate).format("YYYY-MM-DD"));
					currentDate = moment(currentDate).add(1, "days");
				}

				return dates;
			}

			function formatDate(d: any, f = "PPPP") {
				return format(d, f);
			}

			onMounted(() => {
				//fetchProducts();
			});

			return {
				headers,
				processing,
				products,
				createProduct,
				created,
				product,
				updateProduct,
				updated,
				deleteProduct,
				formatDate,
				today,
				week,
				masks,
				getDates,
				setDay,
				day,
				date,
				form,
				farm,
			};
		},
	});
</script>
