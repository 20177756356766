import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Base from "../../../../common/components/Base.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/auth",
		name: "Authentication",
		component: () => import("@/views/LoginRegister.vue"),
		meta: {
			title: "Authentication",
			sidebar: false,
			icon: "growth",
		},
	},
	{
		path: "/",
		name: "Home",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/",
				name: "Home",
				component: Home,
				meta: {
					title: "Home",
					sidebar: true,
					icon: "home-alt",
				},
			},
			{
				path: "/forecasts",
				name: "Forecasts",
				component: () => import("@/views/Forecasts.vue"),
				meta: {
					title: "Orders & Picks Forecasts",
					sidebar: false,
					icon: "calendar",
				},
			},
			{
				path: "/availability",
				name: "Raw Materials",
				component: () => import("@/views/Availability.vue"),
				meta: {
					title: "Raw Material Update",
					sidebar: true,
					icon: "archive-fill",
				},
			},
			{
				path: "/growth",
				name: "Growth Chart",
				component: Home,
				meta: {
					title: "Growth Progress Charts",
					sidebar: false,
					icon: "growth",
				},
			},
			{
				path: "/users",
				name: "User Accounts",
				component: Base,
				meta: {
					title: "User Accounts",
					sidebar: false,
				},
				children: [
					{
						path: "",
						name: "User Accounts",
						component: () => import("@/views/Users.vue"),
						meta: {
							title: "User Accounts",
							sidebar: false,
							icon: "growth",
						},
					},
					{
						path: ":id",
						name: "User Profile",
						component: () => import("@/views/User.vue"),
						meta: {
							title: "User Profile",
							sidebar: false,
						},
					},
				],
			},
		],
	},
	{
		path: "/sales",
		name: "Sales",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/orders",
				name: "Orders",
				component: Base,
				meta: {
					sidebar: true,
					icon: "cart",
				},
				children: [
					{
						path: "",
						name: "Input Order",
						component: () => import("@/views/Orders.vue"),
						meta: {
							title: "Customer Orders",
							sidebar: true,
						},
					},
					{
						path: "reports/week",
						name: "Orders Summary",
						component: () =>
							import("@/views/WeekReport.vue"),
						meta: {
							title: "Weekly Order Summary",
							sidebar: true,
						},
					},
					{
						path: "week",
						name: "Weekly Summary",
						component: () => import("@/views/WeekOrders.vue"),
						meta: {
							title: "Weekly Order Summary",
							sidebar: false,
						},
					},
					{
						path: "day/:day",
						name: "Day Orders",
						component: () => import("@/views/Order.vue"),
						meta: {
							title: "Purchase Order Confirmation",
							sidebar: false,
						},
						props: true
					},
					{
						path: "ten-weeks",
						name: "Sales Forecast",
						component: () => import("@/views/TenWeekOrders.vue"),
						meta: {
							title: "10 Week Order Projection",
							sidebar: true,
						},
					},
					{
						path: "details/:id",
						name: "Order",
						component: () => import("@/views/Order.vue"),
						meta: {
							title: "Purchase Order Confirmation",
							sidebar: false,
						},
					},
				],
			},
		],
	},
	{
		path: "/farm",
		name: "Farm",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/farms",
				name: "Farms",
				component: () => import("@/views/Farms.vue"),
				meta: {
					title: "All Farms",
					sidebar: true,
					icon: "map-pin",
				},
			},
			{
				path: "/packouts",
				name: "Daily Packouts",
				component: () => import("@/views/Packouts.vue"),
				meta: {
					title: "Daily Packouts Summary",
					sidebar: false,
					icon: "box",
				},
			},
			{
				path: "/picks",
				name: "Picks",
				component: Base,
				meta: {
					sidebar: true,
					icon: "clock",
				},
				children: [
					{
						path: "",
						name: "Daily Picks",
						component: () => import("@/views/DayPicks.vue"),
						meta: {
							title: "Daily Picks",
							sidebar: true,
						},
					},
					{
						path: "weekly",
						name: "Weekly Projections",
						component: () => import("@/views/WeekPicks.vue"),
						meta: {
							title: "Weekly Projections",
							sidebar: true,
						},
					},
					{
						path: "week-summary",
						name: "Week Summary",
						component: () =>
							import("@/views/WeekPicksAnalysis.vue"),
						meta: {
							title: "Weekly Pick Summary",
							sidebar: false,
						},
					},
					{
						path: "ten-weeks",
						name: "10 Week Forecast",
						component: () => import("@/views/TenWeekPicks.vue"),
						meta: {
							title: "10 Week Pick Projection",
							sidebar: true,
						},
					},
					{
						path: "day/:date",
						name: "Day Picks",
						component: () => import("@/views/Pick.vue"),
						meta: {
							title: "Day Picks",
							sidebar: false,
						},
					},
					{
						path: "day/:day/all",
						name: "Picks Summary",
						component: () => import("@/views/PickDayReport.vue"),
						meta: {
							title: "Day Pick Report",
							sidebar: false,
						},
						props: true
					},
					{
						path: "week/:date",
						name: "Week Picks",
						component: () => import("@/views/WeekPickSummary.vue"),
						meta: {
							title: "Weekly Projection Analysis",
							sidebar: false,
						},
						props: true,
					},
				],
			},
		],
	},
	{
		path: "/data",
		name: "Data",
		component: Base,
		meta: {
			sidebar: true,
		},
		children: [
			{
				path: "/products",
				name: "Products",
				component: Base,
				meta: {
					title: "All Products",
					sidebar: true,
					icon: "bag",
				},
				children: [
					{
						path: "",
						name: "Products",
						component: () => import("@/views/Products.vue"),
						meta: {
							title: "All Products",
							sidebar: true,
							icon: "bag",
						},
					},

					{
						path: "varieties",
						name: "Crops",
						component: () => import("@/views/Varieties.vue"),
						meta: {
							title: "Crop Varieties",
							sidebar: true,
							icon: "bag",
						},
					},
				],
			},
			{
				path: "/customers",
				name: "Customers",
				component: Base,
				meta: {
					sidebar: true,
					icon: "users",
				},
				children: [
					{
						path: "",
						name: "All Customers",
						component: () => import("@/views/Customers.vue"),
						meta: {
							title: "All Customers",
							sidebar: true,
						},
					},
					{
						path: "assignments",
						name: "Assignments",
						component: () => import("@/views/Assignments.vue"),
						meta: {
							title: "Customer Assignments",
							sidebar: true,
						},
					},
				],
			},

			{
				path: "/agents",
				name: "Freight Agents",
				component: () => import("@/views/Agents.vue"),
				meta: {
					title: "Freight Agents",
					sidebar: true,
					icon: "users-fill",
				},
			},
		],
	},
	{
		path: "/other",
		name: "Reports",
		component: Base,
		meta: {
			sidebar: false,
		},
		children: [
			{
				path: "/reports/day/:today",
				name: "Orders Today",
				component: () =>
					import("@/views/DayReport.vue"),
				meta: {
					title: "Day Order Summary",
					sidebar: false,
					icon: "calendar-alt",
				},
				props: true
			},
			{
				path: "/reports/week/",
				name: "Orders This Week",
				component: () =>
					import("@/views/WeekReport.vue"),
				meta: {
					title: "Weekly All Summary",
					sidebar: false,
					icon: "calendar-alt",
				}
			},
			{
				path: "/reports/week/:day",
				name: "Orders For This Week",
				component: () =>
					import("@/views/WeekReport.vue"),
				meta: {
					title: "Weekly All Summary",
					sidebar: false,
					icon: "calendar-alt",
				},
				props: true
			},
			{
				path: "/reports/month/:day",
				name: "Orders This Month",
				component: () =>
					import("../../../../common/components/Profile.vue"),
				meta: {
					title: "Monthly All Summary",
					sidebar: false,
					icon: "calendar",
				},
				props: true
			},
		],
	},
	{
		path: "/hidden",
		name: "Hidden",
		component: Base,
		meta: {
			sidebar: false,
		},
		children: [
			{
				path: "/profile",
				name: "My Profile",
				component: () =>
					import("../../../../common/components/Profile.vue"),
				meta: {
					title: "My Profile",
					sidebar: false,
				},
			},
			{
				path: "/contact",
				name: "Contact Support",
				component: () =>
					import("../../../../common/components/Contact.vue"),
				meta: {
					title: "Contact Support",
					sidebar: false,
				},
			},
			{
				path: "/tickets",
				name: "Support Tickets",
				component: () =>
					import("../../../../common/components/Tickets.vue"),
				meta: {
					title: "Support Tickets",
					sidebar: false,
				},
			},
			{
				path: "/ticket/:id",
				name: "Support Ticket",
				component: () =>
					import("../../../../common/components/Ticket.vue"),
				meta: {
					title: "Support Ticket",
					sidebar: false,
				},
				props: true,
			},
			{
				path: "/settings",
				name: "App Settings",
				component: () => import("@/views/Settings.vue"),
				meta: {
					title: "App Settings",
					sidebar: false,
					icon: "setting",
				},
			},
		],
	},
	{
		name: "404",
		path: "/:pathMatch(.*)*",
		component: () => import("../../../../common/components/NotFound.vue"),
		meta: {
			title: "Page Not Found",
			sidebar: false,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	linkActiveClass: "current",
	linkExactActiveClass: "active",
	routes,
});

router.beforeEach(function (to, from, next) {
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
	next();
});

router.beforeEach((to, from, next) => {
	const titled = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);
	if (titled) {
		document.title = `${titled.meta.title} - ${process.env.VUE_APP_NAME} | ${process.env.VUE_APP_COMPANY}`;
	}

	next();
});

export default router;
